@import "bootstrap";

html {
  height: 100%;
}

body {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

#app {
  width: 100vw;
  height: 100%;

  .content {
    margin-bottom: 2.5rem + (2 * $navbar-padding-y);
  }

  .sticky-bottom {
    position: fixed;
    bottom: 0;
    z-index: 1020;
    width: 100vw;
  }
}

.spinner {
  margin-top: 5px;
  margin-bottom: 5px;

  i {
    font-size: 40px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.filter-header,
.edit-header {
  position: sticky;
  top: 2.5rem + (2 * $navbar-padding-y);
  z-index: 1000;
  padding: 1rem 0;
  background-color: $dark !important;
}

.filter-header {

  .form-group {

    .pagination {
      margin: 0;
    }
  }
}

.navbar {

  a {
    font-weight: 100;
  }

  &.bg-light {

    .router-link-exact-active,
    .router-link-active {
      color: $navbar-light-active-color;
      background-color: darken($gray-800, 15%);
    }
  }
}

.vdp-datepicker {
  /* stylelint-disable-next-line selector-class-pattern, rule-empty-line-before */
  .vdp-datepicker__calendar {
    background-color: $dropdown-bg;

    header .prev:not(.disabled):hover,
    header .next:not(.disabled):hover,
    header .up:not(.disabled):hover {
      background-color: $dropdown-link-hover-bg;
    }

    .today {
      background-color: $gray-600;
    }

    .weekend {
      background-color: $black;
    }
  }
}

.form-control.lookup-select {
  padding: 0;

  @each $state, $data in $form-validation-states {
    $color: map-get($data, color);
    $icon: map-get($data, icon);

    .was-validated &:#{$state},
    &.is-#{$state} {
      border-color: $color;

      @if $enable-validation-icons {
        padding-right: $custom-select-feedback-icon-padding-right;
        background:
          $custom-select-background,
          $icon $custom-select-bg no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size;
      }

      &:focus {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }
  }

  .btn-drop-down {
    padding: $input-padding-y $input-padding-x;
    color: $input-color;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    // FIXME: should indicate focus somehow on parenting element.
    box-shadow: none;

    &::after {
      content: none;
    }
  }

  ul.dropdown-menu {
    min-width: 100%;
  }
}
