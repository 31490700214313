// Darkly 4.3.1
// Bootswatch
// https://github.com/thomaspark/bootswatch/blob/master/dist/darkly/_bootswatch.scss


// Variables ===================================================================

/*
$web-font-path: "https://fonts.googleapis.com/css?family=Lato:100,400,700,400italic" !default;
@import url($web-font-path);
*/

// Navbar ======================================================================

.bg-primary {

  .navbar-nav .active > .nav-link {
    color: $success !important;
  }
}

.bg-dark {
  background-color: $success !important;

  &.navbar-dark .navbar-nav {

    .nav-link:focus,
    .nav-link:hover,
    .active > .nav-link {
      color: $primary !important;
    }
  }
}

// Buttons =====================================================================

// Typography ==================================================================

.blockquote {

  &-footer {
    color: $gray-600;
  }
}

// Tables ======================================================================

.table {

  &-primary {

    &,
    > th,
    > td {
      background-color: $primary;
    }
  }

  &-secondary {

    &,
    > th,
    > td {
      background-color: $secondary;
    }
  }

  &-light {

    &,
    > th,
    > td {
      background-color: $light;
    }
  }

  &-dark {

    &,
    > th,
    > td {
      background-color: $dark;
    }
  }

  &-success {

    &,
    > th,
    > td {
      background-color: $success;
    }
  }

  &-info {

    &,
    > th,
    > td {
      background-color: $info;
    }
  }

  &-danger {

    &,
    > th,
    > td {
      background-color: $danger;
    }
  }

  &-warning {

    &,
    > th,
    > td {
      background-color: $warning;
    }
  }

  &-active {

    &,
    > th,
    > td {
      background-color: $table-active-bg;
    }
  }

  &-hover {

    .table-primary:hover {

      &,
      > th,
      > td {
        background-color: darken($primary, 5%);
      }
    }

    .table-secondary:hover {

      &,
      > th,
      > td {
        background-color: darken($secondary, 5%);
      }
    }

    .table-light:hover {

      &,
      > th,
      > td {
        background-color: darken($light, 5%);
      }
    }

    .table-dark:hover {

      &,
      > th,
      > td {
        background-color: darken($dark, 5%);
      }
    }

    .table-success:hover {

      &,
      > th,
      > td {
        background-color: darken($success, 5%);
      }
    }

    .table-info:hover {

      &,
      > th,
      > td {
        background-color: darken($info, 5%);
      }
    }

    .table-danger:hover {

      &,
      > th,
      > td {
        background-color: darken($danger, 5%);
      }
    }

    .table-warning:hover {

      &,
      > th,
      > td {
        background-color: darken($warning, 5%);
      }
    }

    .table-active:hover {

      &,
      > th,
      > td {
        background-color: $table-active-bg;
      }
    }
  }
}

// Forms =======================================================================

.input-group-addon {
  color: #ffffff;
}

// Navs ========================================================================

.nav-tabs,
.nav-pills {

  .nav-link,
  .nav-link.active,
  .nav-link.active:focus,
  .nav-link.active:hover,
  .nav-item.open .nav-link,
  .nav-item.open .nav-link:focus,
  .nav-item.open .nav-link:hover {
    color: #ffffff;
  }
}

.breadcrumb a {
  color: #ffffff;
}

.pagination {

  a:hover {
    text-decoration: none;
  }
}

// Indicators ==================================================================

.close {
  opacity: .4;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.alert {
  color: $white;
  border: none;

  a,
  .alert-link {
    color: #ffffff;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {

    &-#{$color} {

      @if $enable-gradients {
        background: $value linear-gradient(180deg, mix($white, $value, 15%), $value) repeat-x;
      } @else {
        background-color: $value;
      }
    }
  }
}

// Progress bars ===============================================================

// Containers ==================================================================


.list-group-item-action {
  color: #ffffff;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: $gray-700;
  }

  .list-group-item-heading {
    color: #ffffff;
  }
}
